<template>
  <b-modal
    id="stakeModal"
    ref="stakeModal"
    hide-header
    hide-footer
    centered
    body-class="text-center text-black mb-4"
    @hide="modalEvent(false)"
    @show="modalEvent(true)"
  >
    <CRow>
      <CCol class="col-12">
        <CLink @click="$refs.stakeModal.hide()" class="float-right">
          <img src="/img/threespace/icon/x-icon.png" />
        </CLink>
      </CCol>
    </CRow>
    <div class="art-pool-modal-title">
      {{ $t("stake.stake") }}
    </div>
    <div class="art-pool-modal-content">
      <div class="art-pool-modal-content-row main_row">
        <div class="first">
          <img src="/img/threespace/logo/logo_100x100.png" />
        </div>
        <div class="amount">
          <div class="currency">
            oPACE<br />
            <b-button @click="onClickMaxBtn" class="max_btn">MAX</b-button>
          </div>
          <b-input
            type="text"
            v-model="stakingAmount"
            :placeholder="$t('stake.amountPlaceholder')"
          />
        </div>
      </div>
      <div class="art-pool-modal-content-row">
        <div class="first">
          {{ $t("stake.walletAmount") }}
        </div>
        <div class="amount">
          <div class="currency">oPACE</div>
          <b-input
            readonly
            type="text"
            class="text-primary"
            :value="wallet.balance.kpace.quantity | currency"
          />
        </div>
      </div>
    </div>
    <div class="w-100 text-center">
      <b-button @click="onClickStakeBtn" class="stake_modal_btn">
        {{ $t("button.next") }}
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "ArtPoolStakeModal",
  data: function () {
    return {
      stakingAmount: "",
    }
  },
  computed: {
    ...mapState(["artpool", "wallet"]),
  },
  methods: {
    show() {
      this.$refs["stakeModal"].show();
    },
    hide() {
      this.$refs["stakeModal"].hide();
    },
    onClickMaxBtn () {
      this.stakingAmount = parseFloat(Math.floor(this.wallet.balance.kpace.quantity * 100) / 100).toFixed(2);
    },
    onClickStakeBtn() {
      let stakingAmount = parseFloat(Math.floor(this.stakingAmount * 100) / 100).toFixed(2);
      let walletAmount = parseFloat(Math.floor(this.wallet.balance.kpace.quantity * 100) / 100).toFixed(2);

      if (this.stakingAmount === "" || stakingAmount < 0.01) {
        alert("스테이킹할 수량을 입력해주세요");
        return;
      }
      if ((Math.round(parseFloat(stakingAmount)*100)/100) > (Math.round(parseFloat(walletAmount)*100)/100)) {
        alert("스테이킹할 수 있는 수량을 초과하였습니다.");
        return;
      }
      this.$emit("onClickStakeBtn", stakingAmount);
    },
    modalEvent(isVisible) {
      if (isVisible) {
        // open event hook
      } else {
        // close event hook ***>> modal data init here
        this.stakingAmount = "";
      }
    }
  },
  filters: {
    currency: value => {
      if (!value) return "";
      return parseFloat(Math.floor(value * 100) / 100)
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
    }
  }
}
</script>
