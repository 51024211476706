<template>
  <div id="ArtPool">
    <div class="art-pool-wrapper">
      <div class="art-pool-head">
        <div class="art-pool-head-left">
          <h1>{{ $t("stake.title") }}</h1>
          <p>{{ $t("stake.titleDesc") }}</p>
        </div>
        <div class="art-pool-head-right">
          <div class="claim-amount">
            <div class="claim-amount-title">
              <img src="/img/threespace/company/opace_logo.png" />
              {{ $t("stake.claimAmountTitle") }}
            </div>
            <p>
              <template v-if="artpool.currentStakingPool">
                {{ artpool.currentStakingPool.userData.userReward | decimal }}
              </template>
              <template v-else>
                0.00
              </template>
              <span>oPACE</span>
            </p>
          </div>
          <div class="claim-button">
            <b-button @click="claim(artpool.currentStakingPool)" :disabled="!artpool.currentStakingPool">
              {{ $t("claim.claim") }}
            </b-button>
          </div>
        </div>
      </div>

      <!-- MarketPlace Token Staked ============================ -->
      <div class="art-pool-content-wrap">
        <img v-if="artpool.stakingPoolInfo.next"
          class="art-pool-shovel left"
          src="/img/threespace/artpool/shovel-left.png"
          @click="getStakingPool(artpool.stakingPoolInfo.next)"
        />
        <div class="art-pool-content">
          <h2 class="art-pool-content-title">
            {{ $t("stake.marketplaceTitle") }} <span class="art-pool-content-title-round">{{artpool.stakingPoolInfo.current}}ROUND</span>
          </h2>
          <div class="art-pool-content-row">
            <!-- token 스테이크: logo_box / NFT 스테이크: img_box -->
            <div class="logo_box">
              <img src="/img/threespace/company/opace_logo.png" />
            </div>
            <div class="btn_box">
              <CButton
                v-if="artpool.currentStakingPool.closed"
                class="art-pool-content-btn closed"
              >
                {{ $t("stake.stake") }}
              </CButton>
              <CButton
                v-else
                class="art-pool-content-btn"
                @click="onOpenStakingModal(artpool.currentStakingPool)"
              >
                {{ $t("stake.stake") }}
              </CButton>
              <CButton
                class="art-pool-content-btn"
                @click="onOpenUnstakingModal(artpool.currentStakingPool)"
              >
                {{ $t("stake.unstake") }}
              </CButton>
              <p>
                {{ artpool.currentStakingPool.startDate | moment("YYYY.MM.DD HH:mm:SS") }}
                &nbsp;-&nbsp;
                {{ artpool.currentStakingPool.endDate | moment("YYYY.MM.DD HH:mm:SS") }} (KST)
              </p>
              <p v-if="artpool.currentStakingPool.closed" class="black">
                {{ $t("stake.closeNoti")}}
              </p>
            </div>
            <div class="info_box">
              <div>
                <p class="info_title">Daily Allocation</p>
                <p class="info_content">{{ getDailyAllocation(artpool.currentStakingPool.rewardPerSecond) }}</p>
              </div>
              <div>
                <p class="info_title">APR(%)</p>
                <p class="info_content">{{getAPR(artpool.currentStakingPool.rewardPerSecond, artpool.currentStakingPool.totalPrincipal) | abbreviateNumber}}%</p>
              </div>
              <div>
                <p class="info_title">My Deposit</p>
                <p class="info_content">{{ artpool.currentStakingPool.userData.userPrincipal | decimal }}</p>
              </div>
              <div>
                <p class="info_title">Total Allocation</p>
                <p class="info_content">{{ getTotalAllocation(artpool.currentStakingPool.rewardPerSecond, artpool.currentStakingPool.startDate, artpool.currentStakingPool.endDate) }}</p>
              </div>
              <div>
                <p class="info_title">Total Staked</p>
                <p class="info_content">{{ artpool.currentStakingPool.totalPrincipal | decimal }}</p>
              </div>
              <div>
                <p class="info_title">Rewards</p>
                <p class="info_content">{{ artpool.currentStakingPool.userData.userReward | decimal }}</p>
              </div>
            </div>
          </div>
        </div>
        <img v-if="artpool.stakingPoolInfo.prev"
             class="art-pool-shovel right"
             src="/img/threespace/artpool/shovel-right.png"
             @click="getStakingPool(artpool.stakingPoolInfo.prev)"
        />
        <img v-if="artpool.currentStakingPool.closed"
             class="art-pool-finished"
             src="/img/threespace/artpool/finished.png"
        />
      </div>

      <!-- coming soon  =================================================  -->
      <div class="art-pool-content-wrap coming_soon_content">
        <div class="art-pool-content">
          <div class="coming_soon_text">
            Coming Soon
          </div>
          <h2 class="art-pool-content-title">
            $PACE 2.0
          </h2>
          <div class="art-pool-content-row coming-soon">
            <!-- token 스테이크: logo_box / NFT 스테이크: img_box -->
            <div class="logo_box">
              <img src="/img/threespace/company/opace_logo.png" />
            </div>
            <div class="btn_box">
              <CButton
                v-if="artpool.currentStakingPool.closed"
                class="art-pool-content-btn closed"
              >
                {{ $t("stake.stake") }}
              </CButton>
              <CButton
                v-else
                class="art-pool-content-btn"
                disabled
              >
                {{ $t("stake.stake") }}
              </CButton>
              <CButton
                class="art-pool-content-btn"
                disabled
              >
                {{ $t("stake.unstake") }}
              </CButton>
              <!-- <p>
                {{ artpool.currentStakingPool.startDate | moment("YYYY.MM.DD HH:mm:SS") }}
                &nbsp;-&nbsp;
                {{ artpool.currentStakingPool.endDate | moment("YYYY.MM.DD HH:mm:SS") }} (KST)
              </p> -->
              <p v-if="artpool.currentStakingPool.closed" class="black">
                {{ $t("stake.closeNoti")}}
              </p>
            </div>
            <div class="info_box">
              <div>
                <p class="info_title">Daily Allocation</p>
                <p class="info_content">10,000</p>
              </div>
              <div>
                <p class="info_title">APR(%)</p>
                <p class="info_content">200%</p>
              </div>
              <div>
                <p class="info_title">My Deposit</p>
                <p class="info_content">0.00</p>
              </div>
              <div>
                <p class="info_title">Total Allocation</p>
                <p class="info_content">900,000.00</p>
              </div>
              <div>
                <p class="info_title">Total Staked</p>
                <p class="info_content">0.00</p>
              </div>
              <div>
                <p class="info_title">Rewards</p>
                <p class="info_content">0.00</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ArtPoolStakeModal
      ref="artPoolStakeModal"
      @onClickStakeBtn="setStake"
    />

    <ArtPoolUnstakeModal
      ref="artPoolUnstakeModal"
      @onClickUnstakeBtn="setUnstake"
    />

    <ArtPoolApproveModal
      ref="artPoolApproveModal"
      @onClickApproveBtn="setApproval"
    />

    <b-modal
      id="klipModal"
      ref="klipModal"
      hide-header
      hide-footer
      centered
      body-class="text-center text-black my-4"
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      @hide="closeModal"
    >
      <CRow>
        <CCol class="col-12">
          <CLink @click="$refs.klipModal.hide()">
            <CIcon name="cil-x" class="float-right" size="lg" />
          </CLink>
        </CCol>
      </CRow>

      <!-- 카카오톡 클립(PC)으로 연결 -->
      <template v-if="form.isKakaoKlipPc">
        <div class="w-100 d-flex justify-content-center align-items-center">
          <img
            src="/img/threespace/company/kakao_klip_logo_color.png"
            width="50"
            class="float-left"
          />
          <h5 class="ml-2 mb-0">{{ $t("wallet.modal.klipQRTitle") }}</h5>
        </div>
        <div class="w-100 d-flex justify-content-center">
          <img :src="klip.qrcode" width="160" height="160" class="border-1" />
        </div>
        <div class="w-100 text-center">
          <p>
            <span class="text-gray mr-2">{{ $t("wallet.modal.remainTime") }}</span>
            <span class="text-danger">{{ time }}</span>
          </p>
        </div>
        <div class="w-100 text-center mt-3">
          <p>
            {{ $t("wallet.modal.klipQRdescription") }}
          </p>
          <p class="text-gray">
            {{ $t("wallet.modal.klipQRdescription2") }}
          </p>
        </div>
        <div class="row d-flex justify-content-center align-items-center mt-3">
          <div class="col-2">
            <img src="/img/threespace/payment/kakaotalk-logo.svg" />
          </div>
          <div class="col-1">
            <img src="/img/threespace/payment/pointer-right.svg" />
          </div>
          <div class="col-2">
            <img src="/img/threespace/payment/kakaotalk-search.svg" />
          </div>
          <div class="col-1">
            <img src="/img/threespace/payment/pointer-right.svg" />
          </div>
          <div class="col-2">
            <img src="/img/threespace/payment/kakaotalk-scan.svg" />
          </div>
        </div>
        <div
          class="row d-flex justify-content-center align-items-center text-center mt-2 mb-5"
        >
          <div class="col-4">{{ $t("wallet.klip.exeKakao") }}</div>
          <div class="col-3">{{ $t("wallet.klip.search") }}</div>
          <div class="col-4">{{ $t("wallet.klip.scanCode") }}</div>
        </div>
      </template>
      <!-- 카카오톡 클립(모바일)으로 연결 -->
      <template v-if="form.isKakaoKlipMobile">
        <div class="w-100 text-center my-5">
          <p>
            <span class="text-gray mr-2">{{ $t("wallet.modal.remainTime") }}</span>
            <span class="text-danger">{{ time }}</span>
          </p>
        </div>
      </template>
    </b-modal>

    <!-- Pace Pool 새로 시작하는 팝업 -->
    <PacePoolStartImageModal
      ref="pacePoolStartImageModal"
      @onClickPopupNoneSeenWeekBtn="onClickPopupNoneSeenWeekBtn"
    />
  </div>
</template>

<script>
import {createNamespacedHelpers, mapActions, mapGetters, mapState} from "vuex";
import Caver from "caver-js";
import ArtPoolStakeModal from "./components/artpool/ArtPoolStakeModal.vue";
import ArtPoolApproveModal from './components/artpool/ArtPoolApproveModal.vue';
import ArtPoolUnstakeModal from './components/artpool/ArtPoolUnstakeModal.vue';
import { prepare, request, getResult } from "klip-sdk";
import QRCode from "qrcode";
import PacePoolStartImageModal from './components/artpool/PacePoolStartImageModal.vue';
import NeopinConnect from "nptconnect-client";

const artpoolHelper = createNamespacedHelpers("artpool");
const authHelper = createNamespacedHelpers("auth");

export default {
  components: { ArtPoolStakeModal, ArtPoolApproveModal, ArtPoolUnstakeModal, PacePoolStartImageModal },
  name: "ArtPool",
  metaInfo() {
    return {
      title: this.$t("meta.artPool.title") + " | 3space Art",
      meta: [
        { vmid: "title", name: "title", content: this.$t("meta.artPool.title") + " | 3space Art" },
        { vmid: "description", name: "description", content: this.$t("meta.artPool.description") },
        { vmid: "og:title", property: "og:title", content: this.$t("meta.artPool.title") + " | 3space Art" },
        { vmid: "og:description", property: "og:description", content: this.$t("meta.artPool.description") },
        { vmid: "og:url", property: "og:url", content: window.location.origin + this.$route.path }
      ]
    }
  },
  data: function () {
    return {
      currentPools: 0,
      totalPools: 0,
      stakingAmount: 0,
      unStakingAmount: 0,
      poolContractAddr: null,
      poolInfo: {},
      date: this.$moment(60 * 3 * 1000),
      form: this.getInitPage(),
      klip: this.getInitKakaoKlip(),
      isOpenApprove: false,
      isOpenStake: false,
      isOpenUnstake: false,
      isOpenClaim: false,
      hasKlaytnAddr: false,
    }
  },
  computed: {
    ...mapGetters("auth", ["isAuthenticated"]),
    ...mapState(["artpool", "wallet"]),
    ...mapState({
      me: (state) => {
        return state.auth.me || {};
      }
    }),
    time: function() {
      return this.date.format("mm : ss");
    },
    isMobile() {
      return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    },
    getWalletProvider() {
      const walletInfo = JSON.parse(localStorage.getItem("walletInfo"));
      const walletProvider = this.me.klaytnProvider || walletInfo.walletProvider ;

      return walletProvider;
    }
  },
  mounted() {
    this.getStakingPools();
    this.$nextTick(() => {
      this.getKlaytnTokenBalance(process.env.VUE_APP_KPACE_ADDRESS, this.me.klaytnAddr);
    });

    // 20220506일 정각에 팝업 노출
    // var startDate = new Date("2022/05/06 00:00:00");
    // if(Date.now() >= startDate) {
    //   if (this.$cookies.get("popupNotSeenForWeekPacePoolStartImageModal") === null) {
    //     this.$refs["pacePoolStartImageModal"].show();
    //   }
    // }
  },
  methods: {
    ...authHelper.mapActions(["verifyTokenCallback"]),
    ...artpoolHelper.mapActions(["getStakingPools", "getStakingPool", "getApprovalStatus"]),
    ...mapActions("wallet", ["getRewardTotal", "getKlaytnTokenBalance", "getNonce", "getTransactionResult"]),
    onClickTabNft() {
      alert("Coming Soon");
      return;
    },
    onClickPopupNoneSeenWeekBtn() {
      this.$cookies.set("popupNotSeenForWeekPacePoolStartImageModal", "done", "7d");
      this.$refs["pacePoolStartImageModal"].hide();
    },
    getAPR(rewardPerSecond, totalPrincipal){
      const dailyReward = rewardPerSecond * 60 * 60 * 24;

      return (dailyReward / totalPrincipal) * 365 * 100;
    },
    getDailyAllocation(rewardPerSecond){
      return (rewardPerSecond * 60 * 60 * 24 / 10 ** 18)
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
    },
    getTotalAllocation(rewardPerSecond, startDate, endDate){
      const start = this.$moment(startDate);
      const end = this.$moment(endDate);
      const durationSeconds = end.diff(start, 'seconds');

      return (rewardPerSecond / 10 ** 18 * durationSeconds)
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
    },
    checkKlaytnAddr() {
      return new Promise((resolve, reject) => {
        if (this.isAuthenticated) {
          this.verifyTokenCallback()
            .then((data) => {
              if (
                this.me === undefined ||
                this.me === null ||
                this.me.idx === undefined ||
                this.me.idx === ""
              ) {
                // 로그인
                this.hasKlaytnAddr = false;
                alert("로그인 후 가능합니다. 로그인 후 다시 시도해주세요.");
              } else {
                if (this.me.klaytnAddr === null || this.me.klaytnAddr === undefined) {
                  this.hasKlaytnAddr = false;
                  alert("카이아 지갑이 필요합니다.")
                } else {
                  this.getKlaytnTokenBalance(process.env.VUE_APP_KPACE_ADDRESS, this.me.klaytnAddr);
                  this.hasKlaytnAddr = true;
                }
              }
              resolve(this.hasKlaytnAddr);
            })
        } else {
          alert("로그인 후 가능합니다. 로그인 후 다시 시도해주세요.");
          reject(this.hasKlaytnAddr);
        }
      })
    },
    onOpenStakingModal(poolInfo) {
      let nowInSeconds = Date.now() / 1000;
      if(nowInSeconds >= parseInt(poolInfo.startTimestamp) && nowInSeconds <= parseInt(poolInfo.endTimestamp)){
        this.checkKlaytnAddr()
          .then((hasKlatynAddr) => {
            if(hasKlatynAddr){
              this.poolInfo = poolInfo;
              this.poolContractAddr = poolInfo.contractAddress;
              this.getApprovalStatus().then((isApproved) => {
                if (isApproved) {
                  this.isOpenStake = true;
                  this.$refs["artPoolStakeModal"].show();
                } else {
                  this.isOpenApprove = true;
                  this.$refs["artPoolApproveModal"].show();
                }
              });
            }
          });
      } else {
        alert("현재 풀이 진행중이 아닙니다.")
      }
    },
    onOpenUnstakingModal(poolInfo) {
      let nowInSeconds = Date.now() / 1000;
      if(nowInSeconds >= parseInt(poolInfo.startTimestamp)){
        this.checkKlaytnAddr()
          .then((hasKlatynAddr) => {
            if (hasKlatynAddr) {
              this.poolInfo = poolInfo;
              this.poolContractAddr = poolInfo.contractAddress;
              this.isOpenUnstake = true;
              this.$refs["artPoolUnstakeModal"].show(poolInfo);
            }
          })
      } else {
        alert("현재 풀이 진행중이 아닙니다.")
      }

    },
    checkKaikas() {
      if (window.klaytn === undefined || !window.klaytn) {
        alert(this.$t("wallet.validation.notFoundKaikas"));
        return false;
      }

      const kaikas = window.klaytn;

      if (
          kaikas.selectedAddress !== undefined &&
          kaikas.selectedAddress !== null &&
          kaikas.selectedAddress !== ""
      ) {
        if (kaikas.selectedAddress.toLowerCase() !== this.me.klaytnAddr.toLowerCase()) {
          alert(this.$t("wallet.validation.checkWalletAddr"));
          return false;
        }
      } else {
        alert(this.$t("wallet.validation.checkWalletAddr"));
        return false;
      }

      if (
        window.klaytn.networkVersion.toString() !==
        process.env.VUE_APP_KLAYTN_CHAIN_ID ||
        window.klaytn.networkVersion === null ||
        window.klaytn.networkVersion === undefined
      ) {
        alert("Check your connected network again");
        return false;
      }

      return true;
    },
    getBalance() {},
    setApproval() {
      const walletProvider = this.getWalletProvider;

      const approvalABI = {
        "inputs": [
          {
            "internalType": "address",
            "name": "spender",
            "type": "address"
          },
          {
            "internalType": "uint256",
            "name": "amount",
            "type": "uint256"
          }
        ],
        "name": "approve",
        "outputs": [
          {
            "internalType": "bool",
            "name": "",
            "type": "bool"
          }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
      };


      if(walletProvider === "Kaikas"){
        this.kaikasApproval(approvalABI);
      }else if(walletProvider === "Klip") {
        this.klipApproval(approvalABI);
      } else if(walletProvider === "Neopin") {
        this.neopinApproval(approvalABI);
      } else {
        alert("We cannot find wallet provider");
      }
    },
    klipApproval(approvalABI){
      const bappName = "3space Art";
      const from = this.me.klaytnAddr;
      const to = process.env.VUE_APP_KPACE_ADDRESS;
      const value = "0";
      const _amount = 16 ** 64;
      const amount = _amount.toLocaleString('fullwide', {useGrouping:false});
      const abi = JSON.stringify(approvalABI);
      const params = `["${this.poolContractAddr}", "${amount}"]`;

      prepare
        .executeContract({ bappName, from, to, value, abi, params })
        .then(data => {
          this.klip.requestKey = data.request_key;
          this.sendNext(data);
        })
        .catch(error => {
          this.$log.error(error);
          this.closeApprove();
          alert("요청에 실패하였습니다. 이용중인 서비스에서 확인 후 다시 시도해주세요.");
        });
    },
    kaikasApproval(approvalABI) {
      if(this.checkKaikas()) {

        const caver = new Caver(window.klaytn);

        const amount = "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"; //최대 수량 64bit
        const data = caver.klay.abi.encodeFunctionCall(
          approvalABI,
          [this.poolContractAddr, amount]
        )

        const result = caver.klay.sendTransaction({
          type: 'SMART_CONTRACT_EXECUTION',
          from: this.me.klaytnAddr,
          to: process.env.VUE_APP_KPACE_ADDRESS,
          gas: 300000,
          data
        }).on('error', this.closeApprove)
          .on('receipt', this.onCompleteApproval)
      }
    },
    neopinApproval(approvalABI) {
      const session = this.getCachedSession();

      if (session) {
        const connector = new NeopinConnect({ session })
        const caver = new Caver();
        const amount = "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff"; //최대 수량 64bit
        const data = caver.klay.abi.encodeFunctionCall(
          approvalABI,
          [this.poolContractAddr, amount]
        )

        this.getNonce(this.me.klaytnAddr).then((nonce) => {
          const tx = {
            from: this.me.klaytnAddr,
            to: process.env.VUE_APP_KPACE_ADDRESS,
            gas: 300000,
            gasPrice: caver.utils.convertToPeb(750, 'Ston'),
            value: 0,
            nonce,
            data
          }

          connector.sendTransaction(tx)
            .then(async (hash) => {
              this.$log.log("sendTransaction hash:", hash);
              await this.sleep(2000);
              this.getTransactionResult(hash).then((receipt) => {
                this.onCompleteApproval(receipt);
              })
                .catch((error) => {
                  this.closeApprove(error);
                })
            })
            .catch((error) => {
              this.$log.error("sendTransaction error:", error);
              this.closeApprove(error);
            })
        })
          .catch((error) => {
            this.$log.error("Can't Get Nonce : ", error);
            this.closeApprove(error);
            alert(error);
          })
      } else {
        alert(this.$t("wallet.validation.checkProvider"));
      }
    },
    onCompleteApproval(receipt){
      if (receipt.status) {
        this.onOpenStakingModal(this.poolInfo);
      } else {
        alert("Approve에 실패하였습니다. 잠시 후 다시 시도해 주세요.")
      }
      this.closeApprove();
    },
    closeApprove(){
      this.isOpenApprove = false;
      this.$refs["artPoolApproveModal"].hide();
    },
    sendNext(data) {
      if (data.err) {
        this.closeOpenedModal();
        if (data.code === 6412) {
          alert("요청에 실패하였습니다. 클레이 수량을 확인해주세요.");
        } else {
          alert("요청에 실패하였습니다. 이용중인 서비스에서 확인 후 다시 시도해주세요.\n[" + data.code + "] " + data.err);
        }
      } else {
        if (data.request_key) {
          // 타이머
          this.isTimeout = false;
          this.date = this.$moment(60 * 3 * 1000); // 3분
          clearInterval(this.timer);
          clearInterval(this.totalTimer);

          if (this.isMobile){
            // 카카오톡 클립 > 정보제공 요청
            request(data.request_key, true);
            this.form = this.getInitPage();
            this.form.isKakaoKlipMobile = true;
          } else {
            // QR 코드 생성
            this.createQRCode("https://klipwallet.com/?target=/a2a?request_key=" + data.request_key);
            if (this.klip.qrcode != null) {
              this.$refs["klipModal"].show();
            }
          }
          // 결과 조회 전체 타이머(QR 남은 시간)
          this.setTotalTimer();
          // 결과 조회
          this.setResultTimer(data.request_key);
        } else {
          this.closeOpenedModal();
          if(this.isMobile){
            alert("요청에 실패하였습니다. 잠시후 다시 시도해 주세요.");
          } else {
            alert("카카오 QR코드 생성에 실패하였습니다. 잠시후 다시 시도해 주세요.");
          }
        }
      }
    },
    setStake(stakingAmount) {

      const walletProvider = this.getWalletProvider;

      const stakeABI = {
          "inputs": [
            {
              "internalType": "uint256",
              "name": "amount",
              "type": "uint256"
            }
          ],
          "name": "stake",
          "outputs": [],
          "stateMutability": "nonpayable",
          "type": "function"
        };

      if(walletProvider === "Kaikas"){
        this.kaikasStake(stakeABI, stakingAmount);
      }else if(walletProvider === "Klip") {
        this.klipStake(stakeABI, stakingAmount);
      } else if(walletProvider === "Neopin") {
        this.neopinStake(stakeABI, stakingAmount);
      } else {
        alert("We cannot find wallet provider");
      }

    },
    kaikasStake(stakeABI, stakingAmount){
      if(this.checkKaikas()) {

        const caver = new Caver(window.klaytn);

        const data = caver.klay.abi.encodeFunctionCall(
          stakeABI,
          ["0x" + (stakingAmount * 10 ** 18).toString(16)]
        );

        const result = caver.klay
          .sendTransaction({
            type: "SMART_CONTRACT_EXECUTION",
            from: this.me.klaytnAddr,
            to: this.poolContractAddr,
            gas: 300000,
            data
          })
          .on("error", this.closeStake)
          .on("receipt", this.onCompleteStake);
      }
    },
    neopinStake(stakeABI, stakingAmount) {
      const session = this.getCachedSession();

      if (session) {
        const connector = new NeopinConnect({ session })
        const caver = new Caver();
        const data = caver.klay.abi.encodeFunctionCall(
          stakeABI,
          ["0x" + (stakingAmount * 10 ** 18).toString(16)]
        );

        this.getNonce(this.me.klaytnAddr).then((nonce) => {
          const tx = {
            from: this.me.klaytnAddr,
            to: this.poolContractAddr,
            gas: 300000,
            gasPrice: caver.utils.convertToPeb(750, 'Ston'),
            value: 0,
            nonce,
            data
          }

          connector.sendTransaction(tx)
            .then(async (hash) => {
              this.$log.log("sendTransaction hash:", hash);
              await this.sleep(2000);
              this.getTransactionResult(hash).then((receipt) => {
                this.onCompleteStake(receipt);
              })
                .catch((error) => {
                  this.closeStake(error);
                })
            })
            .catch((error) => {
              this.$log.error("sendTransaction error:", error);
              this.closeStake(error);
            })
        })
          .catch((error) => {
            this.$log.error("Can't Get Nonce : ", error);
            this.closeStake(error);
            alert(error);
          })
      } else {
        alert(this.$t("wallet.validation.checkProvider"));
      }
    },
    onCompleteStake(receipt){
      if (receipt.status) {
        this.closeStake();
        this.getStakingPool(this.artpool.currentStakingPool.roundNumber);
      } else {
        alert("스테이킹 요청에 실패하였습니다. 잠시 후 다시 시도해 주세요.")
      }
      this.closeStake();
    },
    closeStake(){
      this.isOpenStake = false;
      this.$refs["artPoolStakeModal"].hide();
    },
    klipStake(stakeABI, stakingAmount){
      const bappName = "3space Art";
      const from = this.me.klaytnAddr;
      const to = this.poolContractAddr;
      const value = "0";
      const _amount = stakingAmount * 10 ** 18;
      const amount = _amount.toLocaleString('fullwide', {useGrouping:false});
      const abi = JSON.stringify(stakeABI);
      const params = `["${amount}"]`;

      prepare
        .executeContract({ bappName, from, to, value, abi, params })
        .then(data => {
          this.klip.requestKey = data.request_key;
          this.sendNext(data);
        })
        .catch(error => {
          this.$log.error(error);
          this.closeApprove();
          alert("요청에 실패하였습니다. 이용중인 서비스에서 확인 후 다시 시도해주세요.");
        });
    },
    setUnstake(payload) {

      const walletProvider = this.getWalletProvider;

      const unstakeABI = {
        "inputs": [
        {
          "internalType": "uint256",
          "name": "amount",
          "type": "uint256"
        },
        {
          "internalType": "uint8",
          "name": "round",
          "type": "uint8"
        }
      ],
        "name": "withdraw",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      }

      let { unstakingAmount, roundNumber } = payload;

      if(walletProvider === "Kaikas"){
        this.kaikasUnstake(unstakeABI, unstakingAmount, roundNumber);
      }else if(walletProvider === "Klip") {
        this.klipUnstake(unstakeABI, unstakingAmount, roundNumber);
      } else if(walletProvider === "Neopin") {
        this.neopinUnstake(unstakeABI, unstakingAmount, roundNumber)
      } else {
        alert("We cannot find wallet provider");
      }
    },
    kaikasUnstake(unstakeABI, unstakingAmount, roundNumber){

      if(this.checkKaikas()) {

        const caver = new Caver(window.klaytn);

        const data = caver.klay.abi.encodeFunctionCall(
          unstakeABI,
          ["0x" + (unstakingAmount * 10 ** 18).toString(16), roundNumber]
        )

        const result = caver.klay.sendTransaction({
          type: 'SMART_CONTRACT_EXECUTION',
          from: this.me.klaytnAddr,
          to: this.poolContractAddr,
          gas: 300000,
          data
        }).on('error', this.closeUnstake)
          .on('receipt', this.onCompleteUnstake)
      }
    },
    neopinUnstake(unstakeABI, unstakingAmount, roundNumber){
      const session = this.getCachedSession();

      if (session) {
        const connector = new NeopinConnect({ session })
        const caver = new Caver();
        const data = caver.klay.abi.encodeFunctionCall(
          unstakeABI,
          ["0x" + (unstakingAmount * 10 ** 18).toString(16), roundNumber]
        )

        this.getNonce(this.me.klaytnAddr).then((nonce) => {
          const tx = {
            from: this.me.klaytnAddr,
            to: this.poolContractAddr,
            gas: 300000,
            gasPrice: caver.utils.convertToPeb(750, 'Ston'),
            value: 0,
            nonce,
            data
          }

          connector.sendTransaction(tx)
            .then(async (hash) => {
              this.$log.log("sendTransaction hash:", hash);
              await this.sleep(2000);
              this.getTransactionResult(hash).then((receipt) => {
                this.onCompleteUnstake(receipt);
              })
                .catch((error) => {
                  this.closeUnstake(error);
                })
            })
            .catch((error) => {
              this.$log.error("sendTransaction error:", error);
              this.closeUnstake(error);
            })
        })
          .catch((error) => {
            this.$log.error("Can't Get Nonce : ", error);
            this.closeUnstake(error);
            alert(error);
          })
      } else {
        alert(this.$t("wallet.validation.checkProvider"));
      }
    },
    klipUnstake(unstakeABI, unstakingAmount, roundNumber){
      const bappName = "3space Art";
      const from = this.me.klaytnAddr;
      const to = this.poolContractAddr;
      const value = "0";
      const _amount = unstakingAmount * 10 ** 18;
      const amount = _amount.toLocaleString('fullwide', {useGrouping:false});
      const abi = JSON.stringify(unstakeABI);
      const params = `["${amount}", "${roundNumber}"]`;

      prepare
        .executeContract({ bappName, from, to, value, abi, params })
        .then(data => {
          this.klip.requestKey = data.request_key;
          this.sendNext(data);
        })
        .catch(error => {
          this.$log.error(error);
          this.closeApprove();
          alert("요청에 실패하였습니다. 이용중인 서비스에서 확인 후 다시 시도해주세요.");
        });
    },
    onCompleteUnstake(receipt){
      if(receipt.status){
        this.getStakingPool(this.artpool.currentStakingPool.roundNumber);
        this.closeUnstake();
      }else {
        this.closeUnstake();
      }
    },
    closeUnstake(){
      this.isOpenUnstake = false;
      this.$refs["artPoolUnstakeModal"].hide();
    },
    claim(poolInfo) {
      this.checkKlaytnAddr()
        .then((hasKlatynAddr) => {
          if (hasKlatynAddr) {

            const walletProvider = this.getWalletProvider;

            const claimABI = {
              "inputs": [
                {
                  "internalType": "uint8",
                  "name": "round",
                  "type": "uint8"
                }
              ],
              "name": "claim",
              "outputs": [],
              "stateMutability": "nonpayable",
              "type": "function"
            };


            const userReward = (Math.floor((poolInfo.userData.userReward * 100) / 10 ** 18) / 100)
              .toFixed(2)

            if(userReward < 0.01){
              alert("클레임 할 수 있는 수량이 없습니다.");
              return;
            }

            if (walletProvider === "Kaikas") {
              this.kaikasClaim(claimABI, poolInfo);
            } else if (walletProvider === "Klip") {
              this.klipClaim(claimABI, poolInfo);
            } else if (walletProvider === "Neopin") {
              this.neopinClaim(claimABI, poolInfo);
            } else {
              alert("We cannot find wallet provider");
            }
          }
        })
    },
    kaikasClaim(claimABI, poolInfo) {
      if(this.checkKaikas()) {
        const caver = new Caver(window.klaytn);
        const data = caver.klay.abi.encodeFunctionCall(
          claimABI,
          [poolInfo.roundNumber]
        );

        const result = caver.klay.sendTransaction({
          type: 'SMART_CONTRACT_EXECUTION',
          from: this.me.klaytnAddr,
          to: poolInfo.contractAddress,
          gas: 300000,
          data
        }).on('error', this.onFailClaim)
          .on('receipt', this.onCompleteClaim)
      }
    },
    neopinClaim(claimABI, poolInfo) {
      const session = this.getCachedSession();

      if (session) {
        const connector = new NeopinConnect({ session })
        const caver = new Caver();
        const data = caver.klay.abi.encodeFunctionCall(
          claimABI,
          [poolInfo.roundNumber]
        );

        this.getNonce(this.me.klaytnAddr).then((nonce) => {
          const tx = {
            from: this.me.klaytnAddr,
            to: poolInfo.contractAddress,
            gas: 300000,
            gasPrice: caver.utils.convertToPeb(750, 'Ston'),
            value: 0,
            nonce,
            data
          }

          connector.sendTransaction(tx)
            .then(async (hash) => {
              this.$log.log("sendTransaction hash:", hash);
              await this.sleep(2000);
              this.getTransactionResult(hash).then((receipt) => {
                this.onCompleteClaim(receipt);
              })
                .catch((error) => {
                  this.onFailClaim(error);
                })
            })
            .catch((error) => {
              this.$log.error("sendTransaction error:", error);
              this.onFailClaim(error);
            })
        })
          .catch((error) => {
            this.$log.error("Can't Get Nonce : ", error);
            this.onFailClaim(error);
            alert(error);
          })
      } else {
        alert(this.$t("wallet.validation.checkProvider"));
      }
    },
    klipClaim(claimABI, poolInfo) {
      const bappName = "3space Art";
      const from = this.me.klaytnAddr;
      const to = poolInfo.contractAddress;
      const value = "0";
      const abi = JSON.stringify(claimABI);
      const params = `["${poolInfo.roundNumber}"]`;

      prepare
        .executeContract({ bappName, from, to, value, abi, params })
        .then(data => {
          this.klip.requestKey = data.request_key;
          this.sendNext(data);
        })
        .catch(error => {
          this.$log.error(error);
          this.closeApprove();
          alert("요청에 실패하였습니다. 이용중인 서비스에서 확인 후 다시 시도해주세요.");
        });
    },
    onCompleteClaim(receipt){
      if(receipt.status){
        this.getStakingPool(this.artpool.currentStakingPool.roundNumber);
      }else {
        this.onFailClaim()
      }
    },
    onFailClaim(){
      alert("클레임에 실패하였습니다. 잠시 후 다시 시도해 주세요");
    },
    getInitPage() {
      return {
        title: "CONNECT",//this.$t("wallet.modal.description"),
        isDefault: false,
        isKakaoKlipPc: false,
        isKakaoKlipMobile: false,
        errorMessage: null,
        isLogin: false,
        isChange: false,
        isEthereum: false,
        isKlaytn: false,
      };
    },
    getInitKakaoKlip() {
      return {
        qrcode: null,
        requestKey: null
      };
    },
    createQRCode(_klipURL) {
      QRCode.toDataURL(_klipURL, async (err, url) => {
        this.form = this.getInitPage();
        if (err) {
          this.klip.qrcode = null;
        } else {
          this.form.isKakaoKlipPc = true;
          this.form.title = this.$t("wallet.modal.klipQRTitle");
          this.klip.qrcode = url;
        }
      });
    },
    setTotalTimer() {
      if (!this.isTimeout) {
        this.totalTimer = setInterval(() => {
          this.date = this.date.clone().subtract(1, "seconds");
          if (this.time === "00 : 00") {
            clearInterval(this.timer);
            clearInterval(this.totalTimer);
            this.hide();
          }
        }, 1000);
      }
    },
    setResultTimer() {
      if (!this.isTimeout) {
        this.timer = setInterval(() => {
          getResult(this.klip.requestKey)
            .then(data => {
              if (data.status === "completed") {

                if (data.result.status === "success") {
                  clearInterval(this.timer);
                  this.isTimeout = true;
                  // 열린 모달 닫기
                  this.$refs["klipModal"].hide();
                  this.closeOpenedModal();

                } else if (data.result.status === "pending") {
                  // pending : success될때까지 실행
                  this.isTimeout = false;
                } else {
                  // const slackInfo = {
                  //   type: "PAYMENT", // PAYMENT, ORDER, ARTWORK
                  //   header: "KPACE리워드-전송지연",
                  //   productName: "KPACE CLAIM",
                  //   artistName: this.me.klaytnAddr,
                  //   job: "> Klip API result.status: fail",
                  // };
                  // this.sendSlack(slackInfo);
                  // alert(this.$t("payment.payFailMsg"));
                }
              } else if (data.status === "canceled") {
                this.closeModal();
                this.closeOpenedModal();
              }
            })
            .catch(error => {
              this.$log.error(error);
              clearInterval(this.timer);
            });
        }, 3000);
      }
    },
    closeOpenedModal() {
      if(this.isOpenApprove){
        this.closeApprove();
      }

      if(this.isOpenStake) {
        this.closeStake();
      }

      if(this.isOpenUnstake) {
        this.closeUnstake();
      }

      this.getStakingPool(this.artpool.currentStakingPool.roundNumber);
    },
    closeModal() {
      clearInterval(this.timer);
      clearInterval(this.totalTimer);
    },
    getCachedSession() {
      const local = localStorage ? localStorage.getItem("walletconnect") : null;

      let session = null;
      if (local) {
        session = JSON.parse(local);
      }
      return session;
    },
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
  },
  filters: {
    decimal: value => {
      if (!value) return 0;
      return (Math.floor((value * 100) / 10 ** 18) / 100)
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
    },
    abbreviateNumber: value => {
      if (value === null) { return null; } // terminate early
      if (value === 0) { return '0'; } // terminate early
      var b = value.toPrecision(2).split("e"), // get power
        k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 3), // floor at decimals, ceiling at trillions
        c = k < 1 ? value.toFixed(2) : (value / Math.pow(10, k * 3) ).toFixed(3), // divide by power
        d = c < 0 ? c : Math.abs(c), // enforce -0 is 0
        e = d + ['', 'K', 'M', 'B', 'T'][k]; // append power
      return e;
    }
  }
}
</script>
