<template>
  <b-modal
    id="unstakeModal"
    ref="unstakeModal"
    hide-header
    hide-footer
    centered
    body-class="text-center text-black mb-4"
    @hide="modalEvent(false)"
    @show="modalEvent(true)"
  >
    <CRow>
      <CCol class="col-12">
        <CLink @click="$refs.unstakeModal.hide()" class="float-right">
          <img src="/img/threespace/icon/x-icon.png" />
        </CLink>
      </CCol>
    </CRow>
    <div class="art-pool-modal-title">
      {{ $t("stake.unstake") }}
    </div>
    <div class="art-pool-modal-content">
      <div class="art-pool-modal-content-row main_row">
        <div class="first">
          <img src="/img/threespace/logo/logo_100x100.png" />
        </div>
        <div class="amount">
          <div class="currency">
            oPACE<br />
            <b-button @click="onClickMaxBtn" class="max_btn">MAX</b-button>
          </div>
          <b-input
            type="text"
            v-model="unstakingAmount"
            :placeholder="$t('stake.amountPlaceholder')"
          />
        </div>
      </div>
      <div class="art-pool-modal-content-row">
        <div class="first">
          {{ $t("stake.stakingAmount") }}
        </div>
        <div class="amount">
          <div class="currency">oPACE</div>
          <b-input
            readonly
            type="text"
            class="text-primary"
            :value="poolInfo.userData.userPrincipal | decimal"
          />
        </div>
      </div>
    </div>
    <div class="w-100 text-center">
      <b-button @click="onClickUnstakeBtn" class="stake_modal_btn">
        {{ $t("button.next") }}
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "ArtPoolUnstakeModal",
  data: function () {
    return {
      unstakingAmount: "",
      poolInfo: {
        userData: {}
      }
    }
  },
  computed: {
    ...mapState(["artpool", "wallet"]),
  },
  methods: {
    show(poolInfo) {
      this.poolInfo = poolInfo;
      this.$refs["unstakeModal"].show();
    },
    hide() {
      this.$refs["unstakeModal"].hide();
    },
    onClickMaxBtn () {
      this.unstakingAmount = parseFloat(Math.floor(this.poolInfo.userData.userPrincipal / 10 ** 18 * 100) / 100).toFixed(2);
    },
    onClickUnstakeBtn() {
      let unstakingAmount = parseFloat(Math.floor(this.unstakingAmount * 100) / 100).toFixed(2);
      if (this.unstakingAmount === "" || unstakingAmount < 0.01) {
        alert("언스테이킹할 수량을 입력해주세요");
        return;
      }
      if (unstakingAmount > this.poolInfo.userData.userPrincipal / 10 ** 18) {
        alert("언스테이킹할 수 있는 수량을 초과하였습니다.");
        return;
      }
      const payload = {
        unstakingAmount,
        roundNumber : this.poolInfo.roundNumber
      }

      this.$emit("onClickUnstakeBtn", payload);
    },
    modalEvent(isVisible) {
      if (isVisible) {
        // open event hook
      } else {
        // close event hook ***>> modal data init here
        this.unstakingAmount = "";
      }
    }
  },
  filters: {
    decimal: value => {
      if (!value) return 0;
      return (Math.floor((value * 100) / 10 ** 18) / 100)
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,");
    },
  }
}
</script>
